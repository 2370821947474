import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
   <>
     <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="text-center relative">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
      </div>
    </div>
    <div className="w-full bg-black">
        <svg className="lg:-mt-[18%] lg:block hidden absolute z-[9999] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,96L720,224L1440,128L1440,320L720,320L0,320Z"></path></svg>
      </div>
   </>
  );
};

export default TransparentHeader;
